import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/ui/Text';
import Icon from 'components/ui/Icon';
import styles from './CaseStudyGridFilter.module.scss';

const Select = ({ filters, getActive }) => {
    const handleSelectChange = e => {
        const values = Array.from(e.target.selectedOptions, function (option) {
            return option.value;
        });
        getActive(values);
    };

    return (
        <div className={styles.selectRoot}>
            <select
                className={styles.select}
                multiple
                onChange={handleSelectChange}
            >
                {filters?.map(filterGroup => {
                    return (
                        <optgroup
                            key={filterGroup?.name}
                            label={filterGroup?.name}
                        >
                            {filterGroup?.filters.map(value => {
                                return (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                );
                            })}
                        </optgroup>
                    );
                })}
            </select>
            <div className={styles.selectDisplay}>
                <Text theme="label">Filter Work By</Text>
                <span className={styles.selectDisplayIcon}>
                    <Icon type="chevron8" />
                </span>
            </div>
        </div>
    );
};

Select.propTypes = {
    filters: PropTypes.array,
    getActive: PropTypes.func,
};

export default Select;
