import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useMedia } from 'react-use';
import { breakpoints } from 'utils';
import Icon from 'components/ui/Icon';
import Text from 'components/ui/Text';
import Checkboxes from './Checkboxes';
import Select from './Select';
import styles from './CaseStudyGridFilter.module.scss';

const CaseStudyGridFilter = ({
    filters,
    getActiveFilters,
    getRemovedFilter,
    clearFilters,
    results,
}) => {
    const [activeFilters, setActiveFilters] = useState([]);
    const [removeActiveFilter, setRemoveActiveFilter] = useState([]);
    const [clear, setClear] = useState(false);

    const hasHoverQuery = useMedia('(hover: hover)', false);
    const isMedium = useMedia(`(min-width: ${breakpoints.medium}px)`, false);

    const hasActiveFilters = activeFilters?.length > 0;

    const handleClearClick = () => {
        clearFilters();
        setActiveFilters([]);
        setClear(true);
    };

    const handleCleared = () => {
        setClear(false);
    };

    const handleActiveFilters = filters => {
        getActiveFilters(filters);
        setActiveFilters(filters);
    };

    const handleRemoveActive = e => {
        const filter = e.currentTarget.getAttribute('data-name');
        setRemoveActiveFilter(filter);
        getRemovedFilter(filter);

        const newActive = activeFilters.filter(item => item !== filter);
        setActiveFilters(newActive);
    };

    const showCheckboxes = hasHoverQuery || isMedium;

    return (
        <div
            className={cx(styles.root, {
                [styles.hasActiveFilters]: hasActiveFilters,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.filters}>
                    {showCheckboxes ? (
                        <Checkboxes
                            getActive={handleActiveFilters}
                            filters={filters}
                            clear={clear}
                            cleared={handleCleared}
                            removeActiveFilter={removeActiveFilter}
                            isMedium={isMedium}
                            results={results}
                        />
                    ) : (
                        <Select
                            getActive={handleActiveFilters}
                            filters={filters}
                        />
                    )}
                </div>
                <div className={styles.activeFilters}>
                    {activeFilters?.map((item, i) => {
                        return (
                            <div
                                key={i}
                                data-name={item}
                                className={styles.activeFilterPill}
                                onClick={handleRemoveActive}
                            >
                                <Text theme="thumbTitle">
                                    {item}
                                    <span
                                        className={styles.activeFilterPillIcon}
                                    >
                                        <Icon type="closeSmall" />
                                    </span>
                                </Text>
                            </div>
                        );
                    })}
                </div>
                {hasActiveFilters && (
                    <div className={styles.clear}>
                        <button
                            className={styles.clearButton}
                            disabled={activeFilters?.length === 0}
                            onClick={handleClearClick}
                        >
                            <Text theme="label">Clear All</Text>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

CaseStudyGridFilter.propTypes = {
    filters: PropTypes.array,
    getActiveFilters: PropTypes.func,
    getRemovedFilter: PropTypes.func,
    clearFilters: PropTypes.func,
};

export default CaseStudyGridFilter;
