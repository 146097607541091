import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from 'components/ui/Text';
import Icon from 'components/ui/Icon';
import styles from './CaseStudyGridFilter.module.scss';

const Checkboxes = ({
    filters,
    clear,
    cleared,
    getActive,
    removeActiveFilter,
    isMedium,
    results,
}) => {
    const [activeFilters, setActiveFilters] = useState([]);
    const [collapsed, setCollapsed] = useState(true);

    const handleChange = e => {
        const value = e.currentTarget.value;
        const isActive = activeFilters.find(item => item === value);

        if (isActive) {
            const newValues = activeFilters.filter(item => item !== isActive);
            setActiveFilters(newValues);
            getActive(newValues);
        } else {
            const newValues = [...activeFilters, value];
            setActiveFilters(newValues);
            getActive(newValues);
        }
    };

    useEffect(() => {
        if (clear) {
            setActiveFilters([]);
            cleared();
        }
    }, [clear, cleared]);

    useEffect(() => {
        if (removeActiveFilter.length > 0) {
            const newActive = activeFilters.filter(
                item => item !== removeActiveFilter
            );

            setActiveFilters(newActive);
        }
    }, [activeFilters, removeActiveFilter]);

    return (
        <div
            className={cx(styles.checkboxesRoot, {
                [styles.collapsed]: collapsed,
            })}
        >
            <div className={styles.checkboxesHeadline}>
                <Text
                    theme="label"
                    className={styles.toggleButton}
                    onClick={() => {
                        setCollapsed(!collapsed);
                    }}
                >
                    View Work By
                    <span className={styles.checkboxesHeadlineIcon}>
                        <Icon type="chevron8" />
                    </span>
                </Text>
                {activeFilters.length > 0 && (
                    <Text theme="label">{results.length} results found</Text>
                )}
            </div>
            <div className={styles.checkboxesInner}>
                <div
                    className={styles.checkboxesCollapse}
                    style={{
                        height: collapsed ? 0 : isMedium ? '30rem' : '14.5rem',
                    }}
                >
                    {filters?.map((filterGroup, i) => {
                        return (
                            <div key={i} className={styles.checkboxesGroup}>
                                <Text
                                    theme="label"
                                    className={styles.checkboxesGroupHeader}
                                >
                                    {filterGroup?.name}
                                </Text>
                                <div className={styles.checkboxesGroupInner}>
                                    {filterGroup?.filters.map((value, i) => {
                                        const isActive = activeFilters.find(
                                            item => item === value
                                        );
                                        return (
                                            <div
                                                key={i}
                                                className={cx(styles.checkbox, {
                                                    [styles.active]: isActive,
                                                })}
                                            >
                                                <input
                                                    className={
                                                        styles.checkboxInput
                                                    }
                                                    type="checkbox"
                                                    checked={
                                                        isActive ? true : false
                                                    }
                                                    value={value}
                                                    id={value}
                                                    onChange={handleChange}
                                                />
                                                <label
                                                    className={
                                                        styles.checkboxLabel
                                                    }
                                                    htmlFor={value}
                                                >
                                                    <Text theme="label">
                                                        {value}
                                                    </Text>
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

Checkboxes.propTypes = {
    filters: PropTypes.array,
    clear: PropTypes.bool,
    cleared: PropTypes.func,
    getActive: PropTypes.func,
    removeActiveFilter: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
    isMedium: PropTypes.bool,
};

export default Checkboxes;
