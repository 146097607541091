import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'components/ui/Link';
import Button from 'components/ui/Button';
import Img from 'components/ui/Img';
import Text from 'components/ui/Text';
import { breakpoints } from 'utils';
import CaseStudyGridFilter from './CaseStudyGridFilter';
import styles from './CaseStudyGrid.module.scss';

const CaseStudyGrid = ({
    caseStudies,
    isExpandable,
    thumbsCount,
    relatedProjects,
    hasFilters,
}) => {
    const [showMore, setShowMore] = useState(false);
    const [filteredCaseStudies, setFilteredCaseStudies] = useState(caseStudies);
    const [activeFilters, setActiveFilters] = useState([]);

    const previewThumbsCount = isExpandable ? 9 : 6;

    const deriveUniqTags = tagGroup => {
        const all = caseStudies
            .map(item => {
                return item?.[tagGroup];
            })
            .filter(Boolean); // Filter out falsy values
        const allMerged = Array.prototype.concat(...all);
        return [...new Set(allMerged)];
    };

    const filters = [
        {
            name: 'Client',
            filters: deriveUniqTags('clientTag'),
        },
        {
            name: 'Category',
            filters: deriveUniqTags('category'),
        },
        {
            name: 'Capabilities',
            filters: deriveUniqTags('capabilities'),
        },
        {
            name: 'Type',
            filters: deriveUniqTags('type'),
        },
    ];

    const handleShowAll = () => {
        setShowMore(true);
    };

    const filterByTag = (list, filters) => {
        return list.filter(item =>
            filters.some(filter => item.filterTags?.includes(filter))
        );
    };

    const filterResults = (list, filters) => {
        if (!filters.length) return handleClearFilters();

        const resultsByTag = filterByTag(list, filters);
        setFilteredCaseStudies(resultsByTag);
    };

    const handleActiveFilters = filters => {
        setActiveFilters(filters);
        filterResults(caseStudies, filters);
    };

    const handleRemovedFilter = filter => {
        const newActive = activeFilters.filter(item => item !== filter);
        const newActiveUniq = [...new Set(newActive)];
        setActiveFilters(newActiveUniq);
        filterResults(caseStudies, newActiveUniq);
    };

    const handleClearFilters = () => {
        setActiveFilters([]);
        setFilteredCaseStudies(caseStudies);
    };

    return (
        <div className={styles.root}>
            {hasFilters && filters && (
                <CaseStudyGridFilter
                    filters={filters}
                    getActiveFilters={handleActiveFilters}
                    getRemovedFilter={handleRemovedFilter}
                    clearFilters={handleClearFilters}
                    results={filteredCaseStudies}
                />
            )}
            <div
                className={cx(styles.gridContainer, {
                    [styles.relatedProjects]: relatedProjects,
                })}
            >
                {filteredCaseStudies.length > 0 &&
                    filteredCaseStudies.map(
                        ({ id, slug, title, client, thumbnail }, i) => {
                            if (!showMore && i > thumbsCount - 1) return null;
                            if (!thumbnail || !client || !slug) return null;

                            return (
                                <div
                                    key={`${id}${i}`}
                                    className={styles.container}
                                >
                                    <Link href={`/work/${slug}`}>
                                        <span className={styles.aspect}>
                                            {thumbnail && (
                                                <Img
                                                    className={styles.image}
                                                    src={thumbnail.url}
                                                    width={thumbnail.width}
                                                    height={thumbnail.height}
                                                    sizes={`(max-width: ${breakpoints.medium}px) 50vw, 400px`}
                                                    alt={thumbnail.title}
                                                />
                                            )}
                                        </span>
                                        <Text
                                            className={styles.title}
                                            theme="thumbTitle"
                                        >
                                            {client.title}
                                        </Text>
                                        <Text
                                            className={styles.caption}
                                            theme="label"
                                        >
                                            {title}
                                        </Text>
                                    </Link>
                                </div>
                            );
                        }
                    )}
                {!filteredCaseStudies.length && (
                    <Text theme="label" className={styles.noResults}>
                        Oops, no results matching this criteria. Try another
                        filter combination.
                    </Text>
                )}
            </div>

            {isExpandable &&
                !showMore &&
                filteredCaseStudies.length > previewThumbsCount && (
                    <Button className={styles.button} onClick={handleShowAll}>
                        Show all
                    </Button>
                )}
        </div>
    );
};

CaseStudyGrid.propTypes = {
    caseStudies: PropTypes.array.isRequired,
    isExpandable: PropTypes.bool,
    thumbsCount: PropTypes.number,
    relatedProjects: PropTypes.bool,
    hasFilters: PropTypes.bool,
};

CaseStudyGrid.defaultProps = {
    isExpandable: false,
    thumbsCount: 6,
    relatedProjects: false,
    hasFilters: false,
};

export default CaseStudyGrid;
